<template>
    <client-page>
        <sub-visual visual="안과후기" subTitle="review" />

        <v-container class="mt-20">
            <v-fade-transition leave-absolute>
                <v-overlay v-show="loading" color="white" light>
                    <v-progress-circular indeterminate color="primary" size="150" width="10" />
                </v-overlay>
            </v-fade-transition>
            <v-expand-transition>
                <review-item v-show="!loading && !!review" v-bind="{ review }" opened />
            </v-expand-transition>
        </v-container>

        <!-- list -->
    </client-page>
</template>

<script>
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "../templates/ClientPage.vue";
import ReviewItem from "@/components/client/reviews/review-item.vue";
import api from "@/api";

export default {
    components: {
        SubVisual,
        ClientPage,
        ReviewItem,
    },
    props: {
        _review: String,
    },
    data: () => ({
        loading: false,
        review: null,
    }),
    mounted() {
        if (this._review) this.search();
        else {
            alert("올바르지 않은 요청입니다.");
            this.$router.push("/reviews");
        }
    },
    methods: {
        async search() {
            this.loading = true;
            try {
                const { _review: _id } = this;
                const { review } = await api.v1.eyes.reviews.get({ _id });
                this.review = review;
            } catch (error) {
                alert("올바르지 않은 요청입니다.");
                this.$router.push("/reviews");
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>
