var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--small",
    attrs: {
      "align": "end"
    }
  }, [_vm.isLocationView ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._t("default")], 2) : _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "7",
      "md": "6",
      "lg": "5"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "mr-10",
    attrs: {
      "cols": "3"
    }
  }, [_c('list-control-cols')], 1), _c('v-col', [_c('list-control-area')], 1)], 1)], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "mb-n6"
  }, [_c('list-control-sort')], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }