<template>
    <client-page>
        <sub-visual visual="안과후기" subTitle="review" />

        <v-container>
            <div class="mb-40 mb-md-70" v-if="!_tags">
                <review-list-chips />
            </div>
            <!-- S: best slider -->
            <div class="mb-40 mb-md-70" v-if="!_tags">
                <review-list-best />
            </div>
            <!-- E: best slider -->
            <!-- ui:cols,sido,gungu / spacer / sort  -->
            <!-- S: review-list -->
            <div class="mb-26 mb-md-36">
                <review-list-control />
                <v-divider class="border-2 grey-2 mt-10 mt-md-20" />
                <v-row no-gutters class="mt-10 mt-md-20">
                    <v-col cols="auto">
                        <span class="primary--text font-weight-bold">{{ totalCount?.format?.() || 0 }}</span> 건의 결과를 찾았습니다.
                    </v-col>
                </v-row>
            </div>
            <div>
                <v-row>
                    <v-col cols="12" :md="cols" v-for="review in reviews" :key="review._id">
                        <review-item v-bind="{ review, isBiggerImage }" isLink />
                    </v-col>
                </v-row>

                <div class="mt-40 mt-md-70" v-if="hasMore">
                    <div class="v-btn--group">
                        <v-btn @click="loadMore" x-large outlined rounded color="primary" class="w-100 mw-180px">더 보기</v-btn>
                    </div>
                </div>
            </div>
            <!-- E: review-list -->
        </v-container>
    </client-page>
</template>

<script>
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "../templates/ClientPage.vue";
import ReviewItem from "@/components/client/reviews/review-item.vue";
import ReviewListBest from "@/components/client/reviews/review-list-best.vue";
import ReviewListChips from "@/components/client/reviews/review-list-chips.vue";
import ReviewListControl from "@/components/client/reviews/review-list-control.vue";
import api from "@/api";

export default {
    components: {
        SubVisual,
        ClientPage,
        ReviewItem,
        ReviewListBest,
        ReviewListChips,
        ReviewListControl,
    },
    data: () => ({
        searchKey: null,
        searchValue: null,

        page: 1,
        limit: 10,
        totalCount: 0,

        reviews: [],
    }),
    computed: {
        cols() {
            return this.$route.query.cols || 6;
        },
        keyword() {
            return this.$route.query.keyword;
        },
        area() {
            return this.$route.query.gugun || this.$route.query.sido;
        },
        _tags() {
            return this.$route.query._tags;
        },
        sort() {
            return this.$route.query.sort || "createdAt";
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.totalCount / this.limit) || 1;
        },
        hasMore() {
            return this.totalCount > this.reviews.length;
        },
        isBiggerImage() {
            return this.cols == 12;
        },
    },
    mounted() {
        if (this.keyword) this.searchValue = this.keyword;
        this.init().then(this.search);
    },
    watch: {
        keyword() {
            this.searchValue = this.keyword;
            this.init().then(this.search);
        },
        sort() {
            this.init().then(this.search);
        },
        area() {
            this.init().then(this.search);
        },
        _tags() {
            this.init().then(this.search);
        },
    },
    methods: {
        async init() {
            this.page = 1;
            this.totalCount = 0;
            this.reviews = [];
        },
        async search() {
            let { skip, limit, sort, area, _tags, searchKey, searchValue } = this;
            const regexp = /^＃([\S]+)/;
            if (regexp.test(searchValue)) {
                searchKey = "services";
                searchValue = searchValue.match(regexp)[1];
            }
            const {
                summary: { totalCount },
                reviews,
            } = await api.v1.eyes.reviews.gets({
                headers: { skip, limit },
                params: searchValue ? { sort, area, _tags, searchKey, searchValue } : { sort, area, _tags },
            });

            this.reviews = this.reviews.concat(reviews);
            this.totalCount = totalCount;
        },
        loadMore() {
            this.page += 1;
            this.search();
        },
    },
};
</script>

<style></style>
