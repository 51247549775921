<template>
    <v-chip-group v-bind="$attrs" column :class="'justify-' + justify">
        <v-hover v-slot="{ hover }" v-for="{ text, to, count } in services" :key="text">
            <v-chip :to="to" large :outlined="!isKeyword(text)" :color="isKeyword(text) ? 'primary' : 'grey-2'" exact>
                <v-row no-gutters>
                    <v-col> {{ text }}</v-col>
                    <v-col v-if="withCount && !!to.query.keyword" :class="{ 'ml-4 font-size-14': true, 'primary--text': !hover && !isKeyword(text) }"> {{ count }}</v-col>
                </v-row>
            </v-chip>
        </v-hover>
    </v-chip-group>
</template>

<script>
import { SERVICES } from "@/assets/variables";
const services = Object.values(SERVICES).map(({ text, value: key }) => ({ keyword: `＃${text}`, key }));
export default {
    props: {
        summary: { type: Object, default: () => ({}) },
        justify: { type: String, default: "center" },
        withCount: { type: Boolean, default: false },
    },
    data: () => ({
        selected: null,
    }),
    computed: {
        keyword() {
            return this.$route.query.keyword;
        },
        services() {
            const to = { query: { ...this.$route.query } };
            delete to.query.keyword;
            return [{ text: "＃전체", to }].concat(
                services.map(({ keyword, key }) => ({
                    text: keyword,
                    to: { query: { ...to.query, keyword } },
                    count: this.summary[key],
                }))
            );
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        keyword() {
            this.sync();
        },
        selected() {
            if (this.selected !== this.keyword) this.emit();
        },
    },
    methods: {
        sync() {
            this.selected = this.keyword;
        },
        async emit() {
            this.$router.push({ query: { ...this.$route.query, keyword: this.selected } });
        },
        isKeyword(text) {
            if (text == "＃전체") text = undefined;
            return this.$route.query.keyword == text;
        },
    },
};
</script>
