var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-chip-group', _vm._b({
    class: 'justify-' + _vm.justify,
    attrs: {
      "column": ""
    }
  }, 'v-chip-group', _vm.$attrs, false), _vm._l(_vm.services, function (_ref) {
    var text = _ref.text,
      to = _ref.to,
      count = _ref.count;
    return _c('v-hover', {
      key: text,
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref2) {
          var hover = _ref2.hover;
          return [_c('v-chip', {
            attrs: {
              "to": to,
              "large": "",
              "outlined": !_vm.isKeyword(text),
              "color": _vm.isKeyword(text) ? 'primary' : 'grey-2',
              "exact": ""
            }
          }, [_c('v-row', {
            attrs: {
              "no-gutters": ""
            }
          }, [_c('v-col', [_vm._v(" " + _vm._s(text))]), _vm.withCount && !!to.query.keyword ? _c('v-col', {
            class: {
              'ml-4 font-size-14': true,
              'primary--text': !hover && !_vm.isKeyword(text)
            }
          }, [_vm._v(" " + _vm._s(count))]) : _vm._e()], 1)], 1)];
        }
      }], null, true)
    });
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }