var render = function render(){
  var _vm$totalCount, _vm$totalCount$format;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "visual": "안과후기",
      "subTitle": "review"
    }
  }), _c('v-container', [!_vm._tags ? _c('div', {
    staticClass: "mb-40 mb-md-70"
  }, [_c('review-list-chips')], 1) : _vm._e(), !_vm._tags ? _c('div', {
    staticClass: "mb-40 mb-md-70"
  }, [_c('review-list-best')], 1) : _vm._e(), _c('div', {
    staticClass: "mb-26 mb-md-36"
  }, [_c('review-list-control'), _c('v-divider', {
    staticClass: "border-2 grey-2 mt-10 mt-md-20"
  }), _c('v-row', {
    staticClass: "mt-10 mt-md-20",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "primary--text font-weight-bold"
  }, [_vm._v(_vm._s(((_vm$totalCount = _vm.totalCount) === null || _vm$totalCount === void 0 ? void 0 : (_vm$totalCount$format = _vm$totalCount.format) === null || _vm$totalCount$format === void 0 ? void 0 : _vm$totalCount$format.call(_vm$totalCount)) || 0))]), _vm._v(" 건의 결과를 찾았습니다. ")])], 1)], 1), _c('div', [_c('v-row', _vm._l(_vm.reviews, function (review) {
    return _c('v-col', {
      key: review._id,
      attrs: {
        "cols": "12",
        "md": _vm.cols
      }
    }, [_c('review-item', _vm._b({
      attrs: {
        "isLink": ""
      }
    }, 'review-item', {
      review,
      isBiggerImage: _vm.isBiggerImage
    }, false))], 1);
  }), 1), _vm.hasMore ? _c('div', {
    staticClass: "mt-40 mt-md-70"
  }, [_c('div', {
    staticClass: "v-btn--group"
  }, [_c('v-btn', {
    staticClass: "w-100 mw-180px",
    attrs: {
      "x-large": "",
      "outlined": "",
      "rounded": "",
      "color": "primary"
    },
    on: {
      "click": _vm.loadMore
    }
  }, [_vm._v("더 보기")])], 1)]) : _vm._e()], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }