<template>
    <v-row class="mw-lg-500px row--x-small" align="center">
        <v-col>
            <v-select v-model="phone1" :items="['010', '011', '016', '017', '019']" v-bind="$attrs" />
        </v-col>
        <v-col cols="auto"> - </v-col>
        <v-col>
            <v-text-field v-model="phone2" v-bind="$attrs" />
        </v-col>
        <v-col cols="auto"> - </v-col>
        <v-col>
            <v-text-field v-model="phone3" v-bind="$attrs" />
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: ["value", "label"],
    computed: {
        phone() {
            return [this.phone1, this.phone2, this.phone3].join("-");
        },
    },
    data() {
        return {
            phone1: "010",
            phone2: null,
            phone3: null,
        };
    },
    methods: {
        init() {
            [this.phone1, this.phone2, this.phone3] = this.value.split("-");
        },
        sync() {
            this.$emit("input", this.phone);
        },
    },
    mounted() {
        if (this.value) this.init();
    },
    watch: {
        value() {
            if (this.value !== this.phone) this.init();
        },
        phone() {
            if (this.value !== this.phone) this.sync();
        },
    },
};
</script>

<style></style>
