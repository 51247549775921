var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "visual": "안과후기",
      "subTitle": "review"
    }
  }), _c('v-container', {
    staticClass: "mt-20"
  }, [_c('v-fade-transition', {
    attrs: {
      "leave-absolute": ""
    }
  }, [_c('v-overlay', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      "color": "white",
      "light": ""
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "color": "primary",
      "size": "150",
      "width": "10"
    }
  })], 1)], 1), _c('v-expand-transition', [_c('review-item', _vm._b({
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.loading && !!_vm.review,
      expression: "!loading && !!review"
    }],
    attrs: {
      "opened": ""
    }
  }, 'review-item', {
    review: _vm.review
  }, false))], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }