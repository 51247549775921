var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.bandBanner ? _c('v-card', _vm._b({
    staticClass: "line-height-0",
    attrs: {
      "rounded": "lg",
      "elevation": "0"
    }
  }, 'v-card', {
    to: _vm.to,
    href: _vm.href,
    target: _vm.target
  }, false), [_c('img', {
    attrs: {
      "src": _vm.src,
      "alt": "이미지"
    }
  })]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }