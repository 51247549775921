var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-select', {
    attrs: {
      "items": _vm.items,
      "outlined": "",
      "hide-details": "",
      "dense": ""
    },
    model: {
      value: _vm.cols,
      callback: function ($$v) {
        _vm.cols = $$v;
      },
      expression: "cols"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }