<template>
    <v-row align="end" class="row--small">
        <v-col v-if="isLocationView" cols="auto">
            <slot />
        </v-col>
        <v-col v-else cols="12" sm="7" md="6" lg="5">
            <v-row no-gutters>
                <v-col cols="3" class="mr-10"><list-control-cols /></v-col>
                <v-col><list-control-area /></v-col>
            </v-row>
        </v-col>
        <v-spacer />
        <v-col cols="auto">
            <div class="mb-n6">
                <list-control-sort />
            </div>
        </v-col>
    </v-row>
</template>

<script>
import ListControlArea from "./list-control-area.vue";
import ListControlCols from "./list-control-cols.vue";
import listControlSort from "./list-control-sort.vue";
export default {
    components: {
        ListControlArea,
        ListControlCols,
        listControlSort,
    },
    props: {
        isLocationView: { type: Boolean, default: false },
    },
};
</script>

<style></style>
