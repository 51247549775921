var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "mw-lg-500px row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('v-select', _vm._b({
    attrs: {
      "items": ['010', '011', '016', '017', '019']
    },
    model: {
      value: _vm.phone1,
      callback: function ($$v) {
        _vm.phone1 = $$v;
      },
      expression: "phone1"
    }
  }, 'v-select', _vm.$attrs, false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" - ")]), _c('v-col', [_c('v-text-field', _vm._b({
    model: {
      value: _vm.phone2,
      callback: function ($$v) {
        _vm.phone2 = $$v;
      },
      expression: "phone2"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" - ")]), _c('v-col', [_c('v-text-field', _vm._b({
    model: {
      value: _vm.phone3,
      callback: function ($$v) {
        _vm.phone3 = $$v;
      },
      expression: "phone3"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }