<template>
    <v-card v-if="bandBanner" rounded="lg" elevation="0" class="line-height-0" v-bind="{ to, href, target }">
        <img :src="src" alt="이미지" />
    </v-card>
</template>

<script>
import api from "@/api";
export default {
    data() {
        return {
            isPc: window.innerWidth > 1024,
            resizeListener: null,
            bandBanner: null,
        };
    },
    computed: {
        src() {
            if (this.isPc) return this.bandBanner?.imagePc?.url;
            else return this.bandBanner?.imageMb?.url;
        },
        to() {
            if (!!this.bandBanner?.href && !this.bandBanner?.isOuterLink) return this.bandBanner?.href;
        },
        href() {
            if (!!this.bandBanner?.href && this.bandBanner?.isOuterLink) return this.bandBanner?.href;
        },
        target() {
            if (!!this.href) return "_blank";
        },
    },
    mounted() {
        this.init();
        this.setResizeListener();
    },
    destroyed() {
        window.removeEventListener("resize", this.resizeListener);
    },
    methods: {
        async init() {
            let { bandBanner } = await api.v1.bandBanners.get({ code: this.$attrs.code });
            this.bandBanner = bandBanner;
        },
        setResizeListener() {
            const resizeListener = () => (this.isPc = window.innerWidth > 1024);
            window.addEventListener("resize", resizeListener);
            this.resizeListener = resizeListener;
        },
    },
};
</script>
