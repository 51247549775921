var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "visual": "후기쓰기",
      "subTitle": "CREATE REVIEW"
    }
  }), _c('v-container', [_c('div', {
    staticClass: "mb-40 mb-md-70"
  }, [_c('band-banner', {
    attrs: {
      "code": "review-write-top"
    }
  })], 1), _c('div', {
    staticClass: "mb-6"
  }, [_c('p', {
    staticClass: "font-size-14 grey-a--text text-right"
  }, [_c('span', {
    staticStyle: {
      "color": "rgb(255, 0, 0)"
    }
  }, [_vm._v("*")]), _vm._v(" 필수입력입니다.")])]), _c('div', [_c('v-divider', {
    staticClass: "border-2 grey-2"
  }), _c('form-row', {
    attrs: {
      "index": "1",
      "label": "어느 안과의 후기인가요?",
      "required": ""
    }
  }, [_c('v-text-field', {
    staticClass: "w-100 mw-lg-500px",
    attrs: {
      "rounded": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.review.clinic,
      callback: function ($$v) {
        _vm.$set(_vm.review, "clinic", $$v);
      },
      expression: "review.clinic"
    }
  })], 1), _c('form-row', {
    attrs: {
      "index": "2",
      "label": "어느 선생님께 진료 받으셨나요?"
    }
  }, [_c('v-text-field', {
    staticClass: "w-100 mw-lg-500px",
    attrs: {
      "rounded": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.review.doctor,
      callback: function ($$v) {
        _vm.$set(_vm.review, "doctor", $$v);
      },
      expression: "review.doctor"
    }
  })], 1), _c('form-row', {
    attrs: {
      "index": "3",
      "label": "어떤 진료를 받으셨나요?",
      "required": ""
    }
  }, [_c('v-row', {
    staticClass: "row--medium"
  }, _vm._l(_vm.services, function (service) {
    return _c('v-col', {
      key: service.value,
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-checkbox', {
      attrs: {
        "value": service.value,
        "label": service.text,
        "hide-details": "",
        "dense": "",
        "multiple": ""
      },
      model: {
        value: _vm.review.services,
        callback: function ($$v) {
          _vm.$set(_vm.review, "services", $$v);
        },
        expression: "review.services"
      }
    })], 1);
  }), 1), _c('div', {
    staticClass: "mt-16"
  }, [_c('v-currency-field', {
    staticClass: "charges-total",
    attrs: {
      "prefix": "총 금액",
      "hide-details": "",
      "filled": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_vm._v(" 원 ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.review.charges.total,
      callback: function ($$v) {
        _vm.$set(_vm.review.charges, "total", $$v);
      },
      expression: "review.charges.total"
    }
  })], 1)], 1), _c('form-row', {
    attrs: {
      "index": "4",
      "label": "각 항목에 대한 평가를 내려주세요.",
      "required": ""
    }
  }, [_c('div', {
    staticClass: "mt-n6"
  }, _vm._l(_vm.criteria, function (item) {
    return _c('v-row', {
      key: item.value,
      staticClass: "mt-6",
      attrs: {
        "no-gutters": "",
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto",
        "md": "1"
      }
    }, [_c('span', {
      staticClass: "font-size-14 font-size-md-16 grey-2--text pr-10"
    }, [_vm._v(_vm._s(item.text))])]), _c('v-col', {
      attrs: {
        "cols": "auto",
        "md": "3"
      }
    }, [_c('span', {
      staticClass: "font-size-14 font-size-md-16 grey-a--text"
    }, [_vm._v(_vm._s(item.desc))])]), _c('v-col', {
      attrs: {
        "cols": "12",
        "md": "auto"
      }
    }, [_c('v-rating', {
      attrs: {
        "half-increments": "",
        "color": "warning",
        "background-color": "warning",
        "dense": ""
      },
      model: {
        value: _vm.review.stars[item.value],
        callback: function ($$v) {
          _vm.$set(_vm.review.stars, item.value, $$v);
        },
        expression: "review.stars[item.value]"
      }
    })], 1)], 1);
  }), 1)]), _c('form-row', {
    attrs: {
      "index": "5",
      "label": "연락처를 입력해 주세요.",
      "required": "",
      "comment": "(번호를 정확히 기재하지 않을시 경품이 발송되지 않을 수 있습니다)"
    }
  }, [_c('phone-field', {
    attrs: {
      "rounded": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.review.phone,
      callback: function ($$v) {
        _vm.$set(_vm.review, "phone", $$v);
      },
      expression: "review.phone"
    }
  })], 1), _c('form-row', {
    attrs: {
      "index": "6",
      "label": "진솔한 후기를 적어주세요.",
      "required": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "rounded": "",
      "outlined": "",
      "hide-details": "",
      "placeholder": "제목을 입력하세요."
    },
    model: {
      value: _vm.review.subject,
      callback: function ($$v) {
        _vm.$set(_vm.review, "subject", $$v);
      },
      expression: "review.subject"
    }
  }), _c('v-textarea', {
    staticClass: "mt-12",
    attrs: {
      "rounded": "",
      "outlined": "",
      "hide-details": "",
      "placeholder": "내용을 입력하세요."
    },
    model: {
      value: _vm.review.content,
      callback: function ($$v) {
        _vm.$set(_vm.review, "content", $$v);
      },
      expression: "review.content"
    }
  }), _c('div', {
    staticClass: "text-right mt-4 mb-n10"
  }, [_c('div', {
    staticClass: "font-size-14 font-weight-regular grey--text"
  }, [_c('span', {
    class: {
      'red--text text--lighten-1': _vm.contentLength < 100,
      'primary--text text--lighten-1': _vm.contentLength >= 100
    }
  }, [_vm._v(" " + _vm._s(_vm.contentLength) + " ")]), _vm._v(" / 100 ")])])], 1), _c('form-row', {
    attrs: {
      "index": "7",
      "label": "이미지를 올려주세요.",
      "comment": "(사진 등록 시 '돌체라떼'로 업그레이드!)"
    }
  }, [_c('images-input', {
    attrs: {
      "cols": "2",
      "sm": "2",
      "md": "1"
    },
    model: {
      value: _vm.review.photos,
      callback: function ($$v) {
        _vm.$set(_vm.review, "photos", $$v);
      },
      expression: "review.photos"
    }
  })], 1), _c('form-row', {
    attrs: {
      "index": "8",
      "label": "영수증 혹은 진료 증빙 자료를 올려주세요.",
      "comment": "(진료인증)"
    }
  }, [_c('images-input', {
    attrs: {
      "cols": "2",
      "sm": "2",
      "md": "1"
    },
    model: {
      value: _vm.review.receipts,
      callback: function ($$v) {
        _vm.$set(_vm.review, "receipts", $$v);
      },
      expression: "review.receipts"
    }
  })], 1), _c('div', {
    staticClass: "mt-26 mt-md-36"
  }, [_c('v-card', {
    attrs: {
      "rounded": "md",
      "elevation": "0",
      "color": "grey-f7"
    }
  }, [_c('div', {
    staticClass: "pa-20 pa-md-30"
  }, [_c('div', {
    staticClass: "mb-8"
  }, [_c('strong', {
    staticClass: "font-size-16 font-size-md-16 primary--text"
  }, [_vm._v("유의사항 (or 후기 보상)")])]), _c('ul', {
    staticClass: "letter-list"
  }, [_c('li', {
    staticClass: "font-size-14"
  }, [_c('em', [_vm._v("·")]), _vm._v("리뷰 등록 시 개인정보이용/수집/위탁에 동의한 경우에 한해 이벤트 응모가 됩니다.")]), _c('li', {
    staticClass: "font-size-14 mt-4"
  }, [_c('em', [_vm._v("·")]), _vm._v("리뷰 등록 시 개인정보이용/수집/위탁에 동의한 경우에 한해 이벤트 응모가 됩니다.")]), _c('li', {
    staticClass: "font-size-14 mt-4"
  }, [_c('em', [_vm._v("·")]), _vm._v("리뷰 등록 시 개인정보이용/수집/위탁에 동의한 경우에 한해 이벤트 응모가 됩니다.")]), _c('li', {
    staticClass: "font-size-14 mt-4"
  }, [_c('em', [_vm._v("·")]), _vm._v("리뷰 등록 시 개인정보이용/수집/위탁에 동의한 경우에 한해 이벤트 응모가 됩니다.")])])])])], 1), _c('div', {
    staticClass: "mt-40 mt-md-70"
  }, [_c('div', {
    staticClass: "v-btn--group"
  }, [_c('v-btn', _vm._b({
    staticClass: "w-100 mw-180px",
    attrs: {
      "x-large": "",
      "rounded": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v("제출하기")])], 1)])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }