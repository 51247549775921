<template>
    <draggable v-model="files" class="row no-gutters" draggable=".image-item">
        <v-col v-for="{ file, src } in images" :key="file.name" v-bind="{ cols, sm, md, lg, xl }" class="image-item" style="cursor: pointer">
            <v-menu v-if="mode == 'menu'" offset-y>
                <v-img slot="activator" slot-scope="{ attrs, on }" :aspect-ratio="1 / 1" :src="src" v-bind="attrs" v-on="on" @mouseover="$emit('mouseover', file)" />
                <v-list>
                    <v-list-item @click="remove(file)" class="font-size-14">삭제하기</v-list-item>
                </v-list>
            </v-menu>
            <v-hover v-if="mode == 'hover'" v-slot="{ hover }">
                <v-img :aspect-ratio="1 / 1" :src="src" content-class="d-flex align-center justify-center" @mouseover="$emit('mouseover', file)">
                    <v-fade-transition>
                        <v-btn icon color="black" v-show="hover" @click="remove(file)">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-fade-transition>
                </v-img>
            </v-hover>
        </v-col>
        <v-col slot="footer" v-show="showsAddButton" v-bind="{ cols, sm, md, lg, xl }">
            <v-responsive :aspect-ratio="1 / 1">
                <v-btn tile fab color="grey lighten-2" width="100%" height="100%" @click="onClick">
                    <v-icon :size="addIconSize" color="primary">mdi-plus</v-icon>
                </v-btn>
            </v-responsive>
            <v-file-input v-show="false" ref="file-input" accept="image/*" @change="onChange" />
        </v-col>
    </draggable>
</template>

<script>
import Draggable from "vuedraggable";
export default {
    components: {
        Draggable,
    },
    props: {
        cols: { type: [String, Number], default: "3" },
        sm: { type: [String, Number] },
        md: { type: [String, Number] },
        lg: { type: [String, Number] },
        xl: { type: [String, Number] },
        mode: { type: String, default: "menu" },
        value: { type: Array, default: () => [] },
        maxLength: { type: String, default: "0" },
        addIconSize: { type: String, default: "50" },
    },
    data() {
        return {
            files: this.$props.value || [],
        };
    },
    computed: {
        createObjectURL() {
            if (this.file) return URL.createObjectURL(this.file);
            return null;
        },
        images() {
            return this.files.map((file) => ({ file, src: URL.createObjectURL(file) }));
        },
        showsAddButton() {
            let { maxLength } = this;
            maxLength = +maxLength;
            if (maxLength == 0) return true;
            if (maxLength < 0) return false;
            return (this.files || [])?.length < maxLength;
        },
    },
    watch: {
        value() {
            this.files = this.value;
        },
        files() {
            this.emit();
        },
    },
    methods: {
        onClick() {
            this.$refs["file-input"]?.$el?.getElementsByTagName("input")?.[0]?.click();
        },
        onChange(file) {
            const { name } = file || {};
            const names = (this.files || []).map(({ name }) => name);

            if (names.includes(name)) alert("동일한 이름의 파일이 있습니다.");
            else {
                this.files = [...this.files, file];
                this.emit();
            }
        },
        remove(file) {
            if (!confirm("이미지를 삭제하시겠습니까?")) return;
            this.files = [...this.files.filter((item) => item !== file)];
            this.emit();
        },
        emit() {
            this.$emit("input", this.files);
        },
    },
};
</script>
