<template>
    <v-row no-gutters>
        <v-col class="mr-10">
            <v-select v-model="sido" :items="sidos" placeholder="시/도" outlined hide-details dense clearable />
        </v-col>
        <v-col>
            <v-select v-model="gugun" :items="guguns" placeholder="시/군/구" outlined hide-details dense clearable>
                <div slot="no-data" class="pa-10">먼저 시/도를 선택하세요.</div>
            </v-select>
        </v-col>
    </v-row>
</template>

<script>
import sidoGugun from "@/assets/data/sido-gugun.json";
const sidos = sidoGugun.map(({ name }) => name);

export default {
    data: () => ({
        sido: null,
        gugun: null,

        sidos,
    }),
    computed: {
        guguns() {
            return (sidoGugun.find(({ name }) => name == this.sido)?.gugun || []).map(({ name }) => name);
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        "$route.query.sido"() {
            this.sync();
        },
        "$route.query.gugun"() {
            this.sync();
        },
        sido() {
            let { sido } = this;
            if (sido == null) sido = undefined;
            if (sido !== this.$route.query.sido) {
                if (sido) this.$router.push({ query: { ...this.$route.query, sido } });
                else this.$router.push({ query: { ...this.$route.query, sido, gugun: undefined } });
            }
        },
        gugun() {
            let { gugun } = this;
            if (gugun == null) gugun = undefined;
            if (gugun !== this.$route.query.gugun) {
                this.$router.push({ query: { ...this.$route.query, gugun } });
            }
        },
    },
    methods: {
        sync() {
            this.sido = this.$route.query.sido || null;
            this.gugun = this.$route.query.gugun || null;
        },
    },
};
</script>
