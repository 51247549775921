var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "mr-10"
  }, [_c('v-select', {
    attrs: {
      "items": _vm.sidos,
      "placeholder": "시/도",
      "outlined": "",
      "hide-details": "",
      "dense": "",
      "clearable": ""
    },
    model: {
      value: _vm.sido,
      callback: function ($$v) {
        _vm.sido = $$v;
      },
      expression: "sido"
    }
  })], 1), _c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.guguns,
      "placeholder": "시/군/구",
      "outlined": "",
      "hide-details": "",
      "dense": "",
      "clearable": ""
    },
    model: {
      value: _vm.gugun,
      callback: function ($$v) {
        _vm.gugun = $$v;
      },
      expression: "gugun"
    }
  }, [_c('div', {
    staticClass: "pa-10",
    attrs: {
      "slot": "no-data"
    },
    slot: "no-data"
  }, [_vm._v("먼저 시/도를 선택하세요.")])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }