<template>
    <client-page>
        <sub-visual visual="후기쓰기" subTitle="CREATE REVIEW" />

        <v-container>
            <div class="mb-40 mb-md-70">
                <band-banner code="review-write-top" />
            </div>

            <div class="mb-6">
                <p class="font-size-14 grey-a--text text-right"><span style="color: rgb(255, 0, 0)">*</span> 필수입력입니다.</p>
            </div>

            <div>
                <v-divider class="border-2 grey-2"></v-divider>
                <form-row index="1" label="어느 안과의 후기인가요?" required>
                    <v-text-field v-model="review.clinic" rounded outlined hide-details class="w-100 mw-lg-500px" />
                    <!-- <review-write-clinic v-model="review" prepend-inner-icon="mdi-magnify" rounded outlined hide-details class="w-100 mw-lg-500px" /> -->
                </form-row>
                <form-row index="2" label="어느 선생님께 진료 받으셨나요?">
                    <v-text-field v-model="review.doctor" rounded outlined hide-details class="w-100 mw-lg-500px" />
                </form-row>
                <form-row index="3" label="어떤 진료를 받으셨나요?" required>
                    <v-row class="row--medium">
                        <v-col cols="auto" v-for="service in services" :key="service.value">
                            <v-checkbox v-model="review.services" :value="service.value" :label="service.text" hide-details dense multiple />
                        </v-col>
                    </v-row>
                    <div class="mt-16">
                        <v-currency-field prefix="총 금액" v-model="review.charges.total" hide-details filled class="charges-total">
                            <template #append> 원 </template>
                        </v-currency-field>
                    </div>
                </form-row>
                <form-row index="4" label="각 항목에 대한 평가를 내려주세요." required>
                    <div class="mt-n6">
                        <v-row v-for="item in criteria" :key="item.value" no-gutters align="center" class="mt-6">
                            <v-col cols="auto" md="1">
                                <span class="font-size-14 font-size-md-16 grey-2--text pr-10">{{ item.text }}</span>
                            </v-col>
                            <v-col cols="auto" md="3">
                                <span class="font-size-14 font-size-md-16 grey-a--text">{{ item.desc }}</span>
                            </v-col>
                            <v-col cols="12" md="auto">
                                <v-rating v-model="review.stars[item.value]" half-increments color="warning" background-color="warning" dense />
                            </v-col>
                        </v-row>
                    </div>
                </form-row>
                <form-row index="5" label="연락처를 입력해 주세요." required comment="(번호를 정확히 기재하지 않을시 경품이 발송되지 않을 수 있습니다)">
                    <phone-field v-model="review.phone" rounded outlined hide-details />
                </form-row>
                <form-row index="6" label="진솔한 후기를 적어주세요." required>
                    <v-text-field v-model="review.subject" rounded outlined hide-details placeholder="제목을 입력하세요." />
                    <v-textarea v-model="review.content" rounded outlined hide-details placeholder="내용을 입력하세요." class="mt-12" />
                    <!-- <naver-smarteditor v-model="review.content" class="mt-12" /> -->
                    <div class="text-right mt-4 mb-n10">
                        <div class="font-size-14 font-weight-regular grey--text">
                            <span :class="{ 'red--text text--lighten-1': contentLength < 100, 'primary--text text--lighten-1': contentLength >= 100 }"> {{ contentLength }} </span> / 100
                        </div>
                    </div>
                </form-row>
                <form-row index="7" label="이미지를 올려주세요." comment="(사진 등록 시 '돌체라떼'로 업그레이드!)">
                    <images-input v-model="review.photos" cols="2" sm="2" md="1" />
                </form-row>
                <form-row index="8" label="영수증 혹은 진료 증빙 자료를 올려주세요." comment="(진료인증)">
                    <images-input v-model="review.receipts" cols="2" sm="2" md="1" />
                </form-row>

                <div class="mt-26 mt-md-36">
                    <v-card rounded="md" elevation="0" color="grey-f7">
                        <div class="pa-20 pa-md-30">
                            <div class="mb-8">
                                <strong class="font-size-16 font-size-md-16 primary--text">유의사항 (or 후기 보상)</strong>
                            </div>
                            <ul class="letter-list">
                                <li class="font-size-14"><em>·</em>리뷰 등록 시 개인정보이용/수집/위탁에 동의한 경우에 한해 이벤트 응모가 됩니다.</li>
                                <li class="font-size-14 mt-4"><em>·</em>리뷰 등록 시 개인정보이용/수집/위탁에 동의한 경우에 한해 이벤트 응모가 됩니다.</li>
                                <li class="font-size-14 mt-4"><em>·</em>리뷰 등록 시 개인정보이용/수집/위탁에 동의한 경우에 한해 이벤트 응모가 됩니다.</li>
                                <li class="font-size-14 mt-4"><em>·</em>리뷰 등록 시 개인정보이용/수집/위탁에 동의한 경우에 한해 이벤트 응모가 됩니다.</li>
                            </ul>
                        </div>
                    </v-card>
                </div>

                <div class="mt-40 mt-md-70">
                    <div class="v-btn--group">
                        <v-btn @click="save" x-large rounded color="primary" class="w-100 mw-180px" v-bind="{ loading }">제출하기</v-btn>
                    </div>
                </div>
            </div>
        </v-container>
    </client-page>
</template>

<script>
import api from "@/api";
// import * as cheerio from "cheerio";
import { CRITERIA, SERVICES } from "@/assets/variables";

import FormRow from "@/components/dumb/form-row.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "../templates/ClientPage.vue";
import PhoneField from "@/components/dumb/phone-field.vue";
import BandBanner from "@/components/client/band-banner/band-banner.vue";
// import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
// import ReviewWriteClinic from "@/components/client/reviews/review-write-clinic.vue";
import ImagesInput from "@/components/dumb/images-input.vue";

const services = Object.values(SERVICES);
const criteria = Object.values(CRITERIA);

const initReview = () => ({
    _location: null,

    phone: null,
    clinic: null,
    doctor: null,
    subject: null,
    content: null,
    services: [],
    charges: { total: null },
    stars: {
        hospitality: null,
        description: null,
        treatments: null,
        facilities: null,
        charges: null,
    },

    photos: [],
    images: [],
    receipts: [],
});

export default {
    components: {
        FormRow,
        SubVisual,
        ClientPage,
        PhoneField,
        BandBanner,
        // NaverSmarteditor,
        // ReviewWriteClinic,
        ImagesInput,
    },
    data: () => ({
        me: null,
        review: initReview(),
        services,
        criteria,

        loading: false,
    }),
    computed: {
        hasLoggedIn() {
            return !!this.$store.state.payload?._user;
        },
        inputs() {
            let { receipt, photos, receipts, stars, images, ...inputs } = this.review;

            stars = { ...stars, total: Object.values(stars).reduce((sum, stars) => sum + stars, 0) / Object.keys(stars).length || 0 };
            // if (typeof inputs.content == "string")
            //     images = cheerio
            //         .load(inputs.content)("img")
            //         .map(function(i, el) {
            //             return this.attribs["src"];
            //         })
            //         .toArray();

            return { ...inputs, stars, images };
        },
        contentLength() {
            return this.review?.content?.replace?.(/(<w*([^>]+)>)/gi, "")?.replace?.(/&nbsp;/gi, " ")?.length || 0;
        },
    },
    mounted() {
        if (!this.hasLoggedIn) {
            alert("비회원은 후기를 작성하실 수 없습니다");
            this.$router.replace("/join");
        } else this.init();
    },
    watch: {
        "me.phone"() {
            this.review.phone = this.me.phone;
        },
    },
    methods: {
        async init() {
            const { user } = await api.v1.me.get();
            this.me = user;
        },

        validate() {
            try {
                const { clinic, services, stars, phone, subject, content } = this.inputs;

                if (!clinic) throw new Error("진료 안과를 작성해주세요");

                if (services.length < 1) throw new Error("진료 내용을 선택해주세요");

                const hasLeftoutRating = Object.keys(stars).some((key) => stars[key] == null);
                if (hasLeftoutRating) throw new Error("진료 평가를 내려주세요");

                const [phone1, phone2, phone3] = phone.split("-") || [];
                if (!phone1 || !phone2 || !phone3 || phone1.length < 2 || phone2.length < 3 || phone3.length < 4) throw new Error("연락처를 입력해주세요");

                if (!subject || !content) throw new Error("후기를 적어주세요");
                if (this.contentLength < 100) throw new Error("후기 내용을 100자 이상 적어주세요");
            } catch (error) {
                alert(error?.message);
                return false;
            }
            return true;
        },

        async save() {
            if (!this.validate()) return;

            if (this.loading) return;
            else this.loading = true;

            try {
                const { ...inputs } = this.inputs || {};

                let { review } = await api.v1.eyes.reviews.post(inputs);
                let { _id: _review } = review || {};

                // 이미지&영수증 처리
                let { photos = [], receipts } = this.review;
                for (const [key, photo] of photos.entries()) {
                    photos[key] = (await api.v1.eyes.files.post({ _review, index: `photos/${key}` }, photo))?.file;
                }
                const _photos = photos.map(({ _id }) => _id).filter((value) => value);

                for (const [key, receipt] of receipts.entries()) {
                    receipts[key] = (await api.v1.eyes.files.post({ _review, index: `receipts/${key}` }, receipt))?.file;
                }
                receipts = receipts.map(({ _id }) => ({ _img: _id })).filter((value) => value);

                review = (await api.v1.eyes.reviews.put({ _id: review._id, _photos, receipts }))?.review;

                alert("후기 작성이 완료되었습니다. 관리자 검수 후 승인시 등록이 완료됩니다.");
                this.$router.go(-1);
                this.$router.push("/");
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
